import PropTypes from "prop-types";
import React, { useState } from "react";
import Required from "./required";
import Select from "react-select";

const SelectInput = ({
  extraClasses,
  required,
  options,
  name,
  label,
  onChange,
}) => {
  const [value, setValue] = useState(options[0].value);
  const onSelect = (e) => {
    if (onChange) {
      onChange({ target: { name, value: e.value } });
    }
    setValue(e.value);
  };

  return (
    <div className={`flex flex-col text-lg tracking-wide ${extraClasses}`}>
      <label className="flex items-center" htmlFor={name}>
        {label} {required && <Required />}
      </label>
      <Select
        options={options}
        defaultValue={options[0]}
        name={name}
        onChange={onSelect}
      />
      {/* Hidden input for field bc <Select/> values aren't submitted */}
      <input type="hidden" value={value} name={name} readOnly={true} />
    </div>
  );
};

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectInput;
