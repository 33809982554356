import PropTypes from "prop-types";
import React from "react";
import Required from "./required";

const Input = ({
  label,
  name,
  type,
  required,
  extraClasses,
  placeholder,
  maxLength,
  onChange,
}) => {
  const inputClasses = `border rounded ${
    type !== "color" ? " p-2 w-full" : ""
  }`;
  let input;
  if (type === "textarea") {
    input = (
      <textarea
        rows={4}
        name={name}
        className={inputClasses}
        required={required}
      />
    );
  } else {
    input = (
      <input
        type={type}
        name={name}
        className={inputClasses}
        required={required}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
      />
    );
  }
  return (
    <div className={`flex flex-col text-lg tracking-wide ${extraClasses}`}>
      <label className="flex items-center" htmlFor={name}>
        {label} {required && <Required />}
      </label>
      {input}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  extraClasses: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

export default Input;
