import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";
import Loader from "../components/loader";

const Contact = ({ children, data, formURL, gridSize, googleTag }) => {
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = () => {
    setSubmitting(true);
    /* eslint-disable-next-line */
    gtag_report_conversion(`https://www.idealseat.com/${googleTag}`);
  };

  return (
    <Layout>
      <SEO title={data.title} hasCaptcha={true} hasGoogleTags={true} />
      <div className="max-w-2xl mx-auto py-3 px-6">
        <h1 className="text-4xl text-center border-b">{data.title}</h1>
        <p className="py-2">{data.copy}</p>
        <form
          onSubmit={onSubmit}
          className={`my-12 grid relative gap-6 grid-cols-1 sm:grid-cols-${gridSize}`}
          action={formURL}
          method="POST"
          encType="multipart/form-data"
        >
          {children}
          <div
            className="g-recaptcha"
            data-sitekey="6LeVx8sUAAAAAJ50f27DYXox57JxAlzYN2ZSHgzv"
          />
          <div className={`sm:col-span-${gridSize}`}>
            <Button submit={true} label={submitting ? "Sending..." : "Send"} />
          </div>
          {submitting && (
            <div className="absolute top-0 h-full w-full">
              <Loader extraClasses="pb-24" />
            </div>
          )}
        </form>
      </div>
    </Layout>
  );
};

Contact.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  formURL: PropTypes.string.isRequired,
  gridSize: PropTypes.string.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  googleTag: PropTypes.string.isRequired,
};

export default Contact;
