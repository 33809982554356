import PropTypes from "prop-types";
import React from "react";
import { FaUserCircle, FaChevronDown } from "react-icons/fa";

const Header = ({ header, textColor, logo, link1, link2 }) => {
  return (
    <div
      className="p-3 flex justify-between items-center flex-col md:flex-row md:max-h-18"
      style={{ backgroundColor: header }}
    >
      <img src={logo} className="max-w-3xs" style={{ maxHeight: "3.5rem" }} />
      <div
        className="flex flex-row uppercase font-bold justify-center items-center"
        style={{
          color: textColor,
        }}
      >
        {link1 && <p className="text-sm px-2 hidden sm:block">{link1}</p>}
        {link2 && <p className="text-sm px-2 hidden sm:block">{link2}</p>}
        <FaUserCircle
          className="text-4xl hidden sm:block"
          style={{ color: textColor }}
        />
        <p className="sm:hidden flex items-center">
          MENU <FaChevronDown />
        </p>
      </div>
    </div>
  );
};

Header.propTypes = {
  header: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  link1: PropTypes.string,
  link2: PropTypes.string,
};

export default Header;
