import PropTypes from "prop-types";
import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = ({ footer, textColor }) => {
  const socialStyles = {
    className: "text-xl mx-1 my-5",
    style: { color: textColor },
  };

  return (
    <div
      className="flex flex-row justify-center items-center w-full"
      style={{ backgroundColor: footer }}
    >
      <FaFacebook {...socialStyles} />
      <FaTwitter {...socialStyles} />
      <FaInstagram {...socialStyles} />
    </div>
  );
};

Footer.propTypes = {
  footer: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default Footer;
