import PropTypes from "prop-types";
import React from "react";

const CheckboxInput = ({ name, label, extraClasses, onChange }) => {
  return (
    <div className={`tracking-wide border p-3 rounded ${extraClasses}`}>
      <input type="hidden" name={name} value="no" onClick={onChange} />
      <input
        type="checkbox"
        id={name}
        name={name}
        value="yes"
        onClick={onChange}
      />
      <label className="text-lg px-3" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckboxInput;
