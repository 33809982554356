import PropTypes from "prop-types";
import React from "react";

import { isLight } from "../../utils";
const { default: event } = require("../../images/placeholder.jpg");

const Main = ({
  primary,
  hideSearch,
  accent,
  darkColor,
  background,
  headline,
}) => {
  const renderEvents = () => {
    const events = [];
    const numberOfEvents = 3;
    for (let i = 0; i < numberOfEvents; i++) {
      events.push(
        <div
          key={i}
          className={`bg-white rounded-sm m-3 text-left ${
            i > 0 ? "hidden sm:block" : ""
          }`}
        >
          <img src={event} className="p-2" />
          <p className="text-xs leading-3 px-2" style={{ color: primary }}>
            {/* eslint-disable-next-line */}
            Monday, Oct {19 + i}
          </p>
          <p className="px-2 leading-4 pb-1">Event Name</p>
        </div>
      );
    }
    return events;
  };

  const renderSearch = () => {
    return (
      !hideSearch && (
        <React.Fragment>
          <div className="bg-white border p-4 w-full md:w-3/5" />
          <div
            style={{
              background: accent,
              color: isLight(accent) ? darkColor : "#fff",
            }}
            className="rounded-sm p-2 text-sm my-2"
          >
            View All Events
          </div>
        </React.Fragment>
      )
    );
  };

  return (
    <div
      style={{ backgroundImage: background }}
      className="bg-cover bg-center relative text-center text-gray-900"
    >
      <div
        className="absolute h-full w-full top-0 left-0 z-0 opacity-75"
        style={{ backgroundColor: primary }}
      />
      <div className="flex flex-col justify-center items-center mx-3 py-3 z-10 relative">
        <p className="text-2xl font-bold py-3">
          {headline ? headline : "Join Us For A Show!"}
        </p>
        {renderSearch()}
        <div className="flex flex-row mx-10 ">{renderEvents()}</div>
      </div>
    </div>
  );
};

Main.propTypes = {
  primary: PropTypes.string.isRequired,
  hideSearch: PropTypes.bool.isRequired,
  accent: PropTypes.string.isRequired,
  darkColor: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  headline: PropTypes.string,
};

export default Main;
