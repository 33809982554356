import PropTypes from "prop-types";
import React from "react";
import Footer from "./footer";
import Header from "./header";
import Main from "./main";

import { isLight } from "../../utils";

const { default: defaultBackground } = require("../../images/home-bg.jpg");
const { default: defaultLogo } = require("../../images/logo.png");

const Preview = ({ previewData }) => {
    console.log(defaultLogo);
  const { headline } = previewData;
  const logo = previewData.logo
    ? URL.createObjectURL(previewData.logo)
    : defaultLogo;
  const background = previewData.background
    ? `url(${URL.createObjectURL(previewData.background)})`
    : `url(${defaultBackground})`;
  const hideSearch = previewData["hide search"];
  const footerBackground = previewData["footer background"] || "white";
  const headerBackground = previewData["header background"] || "white";
  const primary = previewData["primary color"] || "#aebcd4";
  const secondary = previewData["secondary color"] || "#536b95";
  const accent = previewData["accent color"] || "#e0b972";
  const link1 = previewData["label 1"] || null;
  const link2 = previewData["label 2"] || null;

  const darkColor = "#333333";
  const darkColors = [darkColor];
  const lightColors = ["#ffffff"];
  const sortColor = (color) => {
    if (isLight(color)) {
      lightColors.unshift(color);
    } else {
      darkColors.unshift(color);
    }
  };

  sortColor(accent);
  sortColor(secondary);
  sortColor(primary);

  const textColors = {
    [primary]: isLight(primary) ? darkColors[0] : lightColors[0],
    [secondary]: isLight(secondary) ? darkColors[0] : lightColors[0],
    [accent]: isLight(accent) ? darkColors[0] : lightColors[0],
    white: !isLight(accent) ? accent : darkColors[0],
    black: isLight(accent) ? accent : lightColors[0],
  };

  const getBackground = (color) => {
    switch (color) {
      case "primary":
        return primary;
      case "secondary":
        return secondary;
      case "accent":
        return accent;
      default:
        return color;
    }
  };
  const header = getBackground(headerBackground);
  const footer = getBackground(footerBackground);
  const mainData = {
    primary,
    hideSearch,
    accent,
    darkColor,
    background,
    headline,
  };
  const headerData = { header, logo, link1, link2 };

  return (
    <div className="sm:col-span-6 border-2 rounded-md bg-white">
      <Header {...headerData} textColor={textColors[header]} />
      <Main {...mainData} />
      <Footer {...{ footer }} textColor={textColors[footer]} />
    </div>
  );
};

Preview.propTypes = {
  previewData: PropTypes.object.isRequired,
};

export default Preview;
