import PropTypes from "prop-types";
import React, { useState } from "react";
import Required from "./required";

const ImageUpload = ({
  name,
  label,
  extraClasses,
  required,
  instructions,
  imageType,
  onChange,
}) => {
  const [image, setImage] = useState();

  const onDrop = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0].name);
      if (onChange) {
        onChange({ target: { name, value: e.target.files[0] } });
      }
    }
  };

  return (
    <div className={`tracking-wide relative ${extraClasses}`}>
      <p className="text-lg flex items-center">
        {label} {required && <Required />}
      </p>
      <p className="italic text-sm">{instructions}</p>
      <div className="relative py-3">
        <label className="inline py-2" htmlFor={name}>
          {/* file upload input is very ugly by default in all browsers */}
          {/* bc the input label can be used to activate the input, */}
          {/* we're using the following span to create our own upload button */}
          <span className="bg-lightgrey hover:bg-gray-300 py-2 px-6 text-sm rounded text-center tracking-wide z-10 cursor-pointer min-w-32">
            Upload Image
          </span>
          {/* file input that is hidden but used behind the scenes */}
          {/* this hack for file input allows for browser validation */}
          <input
            onChange={onDrop}
            type="file"
            name={name}
            id={name}
            className="absolute -z-1 opacity-0 max-w-5xs left-0"
            required={required}
            accept={`${imageType ? imageType : "image/*"}`}
          />
        </label>
        <span className="px-3 truncate">{image}</span>
      </div>
    </div>
  );
};

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  required: PropTypes.bool,
  instructions: PropTypes.string,
  imageType: PropTypes.string,
  onChange: PropTypes.func,
};

export default ImageUpload;
