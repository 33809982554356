import React from "react";
import Contact from "../components/contact";
import Input from "../components/input";
import Select from "../components/selectInput";
import ImageUpload from "../components/upload";
import Checkbox from "../components/checkboxInput";
import Heading from "../components/questionnaireHeading";
import Preview from "../components/preview";
import Required from "../components/required";

import data from "../../content/forms/questionnaire.json";

const colorOptions = [
  { value: "white", label: "White" },
  { value: "black", label: "Black" },
  { value: "primary", label: "Primary Color" },
  { value: "secondary", label: "Secondary Color" },
  { value: "accent", label: "Accent Color" },
];

const INITIAL_STATE = {
  "hide search": false,
};

class Form extends React.Component {
  state = INITIAL_STATE;

  handleInputChange = (e) => {
    let value = e.target.value;
    if (e.target.name.includes('color') && !(value.startsWith("#"))) {
        value = "#" + value;
    }
    this.setState({ [e.target.name]: value });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      "hide search": !prevState["hide search"],
    }));
  };

  render() {
    return (
      <Contact
        data={data}
        formURL="https://formkeep.com/f/414a8eb90739"
        gridSize="6"
        googleTag="questionnaire"
      >
        <Input
          type="text"
          name="name"
          label="Full Name"
          required={true}
          extraClasses="sm:col-span-3"
        />
        <Input
          type="email"
          name="email"
          label="Email"
          required={true}
          extraClasses="sm:col-span-3"
        />
        <Input
          type="text"
          name="organization"
          label="Organization Name"
          required={true}
          extraClasses="sm:col-span-3"
        />
        <div className="sm:col-span-3 flex justify-center items-center">
          <Input
            type="text"
            name="domain"
            label="Desired Domain"
            required={true}
            extraClasses="w-full"
          />
          <p className="text-lg mt-10 ml-1 tracking-wide">.idealseat.com</p>
        </div>

        <Heading heading="Brand Colors" />
        <Input
          type="text"
          name="primary color"
          label="Primary Color"
          required={true}
          extraClasses="sm:col-span-2"
          placeholder="#000000"
          onChange={this.handleInputChange}
        />
        <Input
          type="text"
          name="secondary color"
          label="Secondary Color"
          required={true}
          extraClasses="sm:col-span-2"
          placeholder="#000000"
          onChange={this.handleInputChange}
        />
        <Input
          type="text"
          name="accent color"
          label="Accent Color"
          required={true}
          extraClasses="sm:col-span-2"
          placeholder="#000000"
          onChange={this.handleInputChange}
        />
        <Select
          extraClasses="sm:col-span-3"
          options={colorOptions}
          name="header background"
          label="Header Background Color"
          required={true}
          onChange={this.handleInputChange}
        />
        <Select
          extraClasses="sm:col-span-3"
          options={colorOptions}
          name="footer background"
          label="Footer Background Color"
          required={true}
          onChange={this.handleInputChange}
        />

        <Heading heading="Brand Styling" />
        <Input
          type="text"
          name="headline"
          label="Homepage Headline"
          required={true}
          extraClasses="sm:col-span-6"
          onChange={this.handleInputChange}
        />
        <Checkbox
          label="Check to hide searchbar on homepage."
          name="hide search"
          extraClasses="sm:col-span-6"
          onChange={this.handleCheckboxChange}
        />
        <ImageUpload
          label="Homepage Background Image"
          name="background"
          extraClasses="sm:col-span-6"
          required={true}
          instructions="High quality landscape image that is at least 1500px wide."
          onChange={this.handleInputChange}
        />
        <ImageUpload
          label="Brand Logo"
          name="logo"
          extraClasses="sm:col-span-6"
          required={true}
          instructions="Horizontal version of logo that is a PNG file with transparent background."
          imageType=".png"
          onChange={this.handleInputChange}
        />
        <ImageUpload
          label="Favicon"
          name="favicon"
          extraClasses="sm:col-span-6"
          required={true}
          instructions="Square icon used to represent your site. 64px x 64px sized .png transparent background image."
          imageType=".png"
        />

        <Heading heading="External Links" />
        <p className="text-lg tracking-wide sm:col-span-6">
          Header Links
          <br />
          <span className="italic text-sm">
            List up to 2 external links for the site&lsquo;s header. Links must
            begin with either &ldquo;http:&ldquo; or &ldquo;https:&ldquo;, and
            link label has a maximum length of 20 characters.
          </span>
        </p>
        <Input
          type="url"
          name="link 1"
          label="Link 1"
          extraClasses="sm:col-span-3"
          placeholder="https://www.example.com"
        />
        <Input
          type="text"
          name="label 1"
          label="Link 1 Label"
          extraClasses="sm:col-span-3"
          maxLength={20}
          onChange={this.handleInputChange}
        />
        <Input
          type="url"
          name="link 2"
          label="Link 2"
          extraClasses="sm:col-span-3"
          placeholder="https://www.example.com"
        />
        <Input
          type="text"
          name="label 2"
          label="Link 2 Label"
          extraClasses="sm:col-span-3"
          maxLength={20}
          onChange={this.handleInputChange}
        />
        <p className="text-lg tracking-wide sm:col-span-6">
          Social Media Links
          <br />
          <span className="italic text-sm">
            List up to 5 social media links for your site&lsquo;s footer.
          </span>
        </p>
        <Input
          type="textarea"
          name="social links"
          extraClasses="sm:col-span-6"
        />
        <div className={`flex flex-col text-lg tracking-wide sm:col-span-6`}>
          <label class="checkbox-inline">
            <Required />
            <input
              type="checkbox"
              name="terms"
              id="terms"
              class="sm:col-span-6"
              required={true}
            />
            <span>
                &nbsp;I have read and agree to be bound to the terms presented
                in the&nbsp;
              <a
                className="obviousLink"
                href="https://www.idealseat.com/termsandconditions">
                  Terms and Conditions
              </a>
              &nbsp;and the&nbsp;
              <a
                className="obviousLink"
                href="https://www.idealseat.com/privacypolicy">
                  Privacy Policy
              </a>.
            </span>
          </label>
        </div>
        <Heading heading="Preview" />
        <span className="italic text-sm sm:col-span-6">
          Note: This preview is a basic approximation of your design and is not
          to be interpreted as an exact mockup. The final product may appear
          slightly different.
        </span>
        <Preview previewData={this.state} />
      </Contact>
    );
  }
}

export default Form;
