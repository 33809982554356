import PropTypes from "prop-types";
import React from "react";

const QuestionnaireHeading = ({ heading }) => {
  return <h3 className="text-3xl sm:col-span-6 pt-12 border-b">{heading}</h3>;
};

QuestionnaireHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default QuestionnaireHeading;
